const headersSecondSection = [
  {
    text: 'Orden',
    value: 'order',
    sortable: false
  },
  {
    text: 'Nombre Item',
    value: 'name',
    sortable: false
  },
  {
    text: 'Link',
    value: 'webSiteUrl',
    sortable: false
  },
  {
    text: '',
    value: 'arrows',
    sortable: false
  },
  {
    text: '',
    value: 'isActive',
    sortable: false
  },
  {
    text: '',
    value: 'actions',
    sortable: false
  }
]
export default headersSecondSection
