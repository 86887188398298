var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.fair)?_c('fair-layout',{attrs:{"fair-id":_vm.fair.id},scopedSlots:_vm._u([{key:"banner",fn:function(){return [(_vm.fair)?_c('banner',{attrs:{"title":_vm.fair.name,"type":"banner--admin","options":{ padding: '30px' }}}):_vm._e()]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"px-7"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex align-center justify-space-between"},[_c('h2',[_vm._v("Configuración Información General")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-3"},[_c('label',{staticClass:"label-chooser"},[_vm._v("Banner Interno")]),_c('file-chooser',{ref:"generalInformationFileChooser",attrs:{"options":{
              height: '110px',
              description: 'Haz click para cargar la imagen',
              regex: /\.(jpg|jpeg|png|gif)$/,
              error: 'Solo se permiten imagenes',
              fileSize: 2
            }},on:{"input":_vm.onLoadImageUrl}})],1),_c('div',{staticClass:"col-sm-12 col-md-9"},[_c('div',{staticClass:"row justify-end align-content-center"},[_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('h3',[_vm._v("Bloque 1: "),_c('span',{domProps:{"textContent":_vm._s(_vm.firstSection.name)}})]),_c('h3',[_vm._v("Bloque 2: "),_c('span',{domProps:{"textContent":_vm._s(_vm.secondSection.name)}})])]),_c('div',{staticClass:"col-auto"},[_c('v-btn',{staticClass:"text--primary text-none elevation-0 px-5",attrs:{"color":"secondary","dark":""},on:{"click":function($event){return _vm.openTitlesSectionsModal()}}},[_vm._v(" Editar ")])],1)]),_c('titles-sections-modal',{ref:"titlesSectionsModal",on:{"onClose":_vm.onClose}})],1)]),_c('first-section',{attrs:{"fair":_vm.fair,"section":_vm.firstSection}}),_c('second-section',{attrs:{"fair":_vm.fair,"section":_vm.secondSection}})],1)]},proxy:true}],null,false,3597280315)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }