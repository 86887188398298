<template>
  <v-row justify="center">
    <v-dialog v-model="isActive" max-width="800px" persistent scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">
            Títulos bloques
          </span>
        </v-card-title>
        <v-card-text>
          <validation-observer ref="form">
            <v-container>
              <div class="row" v-for="(item, i) in items" :key="i">
                <div class="col-12 mb-0 pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="`Título Bloque ${i + 1}`"
                    vid="name"
                    rules="required|max:100"
                  >
                    <v-text-field
                      v-model="item.name"
                      type="text"
                      outlined
                      dense
                      :error-messages="errors"
                      :label="`Bloque ${i + 1}`"
                      required
                    />
                  </validation-provider>
                </div>
              </div> </v-container
          ></validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="save">
            Aceptar
          </v-btn>
          <v-btn color="red" text @click="close">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    ></v-row
  >
</template>

<script>
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import { GENERAL_INFORMATION_URL } from '@/constants/ServicesMobileConstants'

export default {
  name: 'TitlesSectionsModal',
  data () {
    return {
      items: [],
      isActive: false
    }
  },
  methods: {
    save () {
      this.putMobile(GENERAL_INFORMATION_URL + '/blocks', {
        blocks: this.items
      })
        .then((res) => {
          this.showSuccess()
          this.$emit('onClose', res.data)
          this.close()
        })
        .catch(error => {
          console.info(error)
        })
    },
    open (blocks) {
      this.items = []
      blocks.forEach(element => {
        this.items.push({ ...element })
      })
      this.isActive = true
    },
    close () {
      this.isActive = false
      this.clean()
    },
    clean () {
      this.items = []
    }
  },
  mixins: [notificationMixin, crudServiceMobileMixin]
}
</script>
