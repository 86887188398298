<template>
  <fair-layout v-if="fair" :fair-id="fair.id">
    <template v-slot:banner>
      <banner
        v-if="fair"
        :title="fair.name"
        type="banner--admin"
        :options="{ padding: '30px' }"
      >
      </banner>
    </template>
    <template v-slot:content>
      <div class="px-7">
        <div class="row">
          <div class="col-12 d-flex align-center justify-space-between">
            <h2>Configuración Información General</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-3">
            <label class="label-chooser">Banner Interno</label>
            <file-chooser
              ref="generalInformationFileChooser"
              @input="onLoadImageUrl"
              :options="{
                height: '110px',
                description: 'Haz click para cargar la imagen',
                regex: /\.(jpg|jpeg|png|gif)$/,
                error: 'Solo se permiten imagenes',
                fileSize: 2
              }"
            ></file-chooser>
          </div>
          <div class="col-sm-12 col-md-9">
            <div class="row justify-end align-content-center">
              <div class="col-sm-12 col-md-6">
                <h3>Bloque 1: <span v-text="firstSection.name"></span></h3>
                <h3>Bloque 2: <span v-text="secondSection.name"></span></h3>
              </div>
              <div class="col-auto">
                <v-btn
                  color="secondary"
                  class="text--primary text-none elevation-0 px-5"
                  @click="openTitlesSectionsModal()"
                  dark
                >
                  Editar
                </v-btn>
              </div>
            </div>
            <titles-sections-modal
              ref="titlesSectionsModal"
              @onClose="onClose"
            />
          </div>
        </div>
        <first-section
          :fair="fair"
          :section="firstSection"
        ></first-section>
        <second-section
          :fair="fair"
          :section="secondSection"
        ></second-section>
      </div>
    </template>
  </fair-layout>
</template>
<script>
import FairLayout from '@/layouts/FairLayout'
import { FAIRS_URL } from '@/constants/ServicesConstants'
import { GENERAL_INFORMATION_URL } from '@/constants/ServicesMobileConstants'
import sessionMixin from '@/mixins/sessionMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import Banner from '@/components/banner/Banner'
import FirstSection from './components/FirstSection'
import SecondSection from './components/SecondSection'
import TitlesSectionsModal from './components/TitlesSectionsModal.vue'
import crudServiceMixin from '../../../mixins/crudServiceMixin'
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import FileChooser from '@/components/file-chooser/FileChooser'

export default {
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (sessionMixin.methods.isSuperAdmin(user.roleId)) {
      next()
    } else {
      next({ path: '/error-403' })
    }
  },
  components: {
    FairLayout,
    FirstSection,
    SecondSection,
    TitlesSectionsModal,
    FileChooser,
    Banner
  },
  data () {
    return {
      url: GENERAL_INFORMATION_URL,
      params: {
        fairId: this.$route.params.fairId
      },
      fair: null,
      blocks: []
    }
  },
  methods: {
    async getFair () {
      const response = await this.get(FAIRS_URL + '/' + this.params.fairId)
      this.fair = response.data
      await this.getAppModule()
    },
    async getInformationBlocks () {
      const response = await this.getMobile(this.url + `/blocks?fairId=${this.params.fairId}`)
      this.blocks = response.data
    },
    onLoadImageUrl (file) {
      if (file) {
        const formData = new FormData()
        formData.append('fairId', this.fair.id)
        formData.append('appModuleImage', file)
        this.postMobile(GENERAL_INFORMATION_URL + '/image', formData)
          .then(res => {
            this.showSuccess(res.data.success)
          })
          .catch(err => this.showError(err.response.data.error))
      }
    },
    onClose (blocks) {
      this.blocks = blocks
      this.titleFirstSection = blocks[0]?.name
      this.titleSecondSection = blocks[1]?.name
    },
    openTitlesSectionsModal () {
      this.$refs.titlesSectionsModal.open(this.blocks)
    },
    async getAppModule () {
      const response = await this.getMobile(
        GENERAL_INFORMATION_URL + '/app-modules/' + this.$route.params.fairId
      )
      this.appModule = response.data
      this.$nextTick(() => {
        if (this.appModule.internalImageUrl) {
          this.$refs.generalInformationFileChooser.setImage(
            this.appModule.internalImageUrl,
            true
          )
        }
      })
    }
  },
  computed: {
    firstSection () {
      if (!this.blocks[0]) {
        return {}
      }
      return this.blocks[0]
    },
    secondSection () {
      if (!this.blocks[1]) {
        return {}
      }
      return this.blocks[1]
    }
  },
  async created () {
    try {
      this.showLoader()
      await this.getFair()
      await this.getInformationBlocks()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  mixins: [
    loaderMixin,
    notificationMixin,
    crudServiceMixin,
    crudServiceMobileMixin
  ],
  name: 'GeneralInformation'
}
</script>
