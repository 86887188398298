<template>
  <v-row justify="center">
    <v-dialog v-model="isActive" max-width="800px" persistent scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ model.id ? "Editar" : "Crear" }} Actividad
          </span>
        </v-card-title>
        <v-card-text>
          <validation-observer ref="form">
            <v-container >
              <div class="row">
                <div class="col-12 mb-0 pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Nombre Item"
                    vid="name"
                    rules="required|max:100"
                  >
                    <v-text-field
                      v-model="model.name"
                      type="text"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Nombre Item"
                      required
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mb-0 pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Url Sitio Web"
                    vid="webSiteUrl"
                    rules="max:255"
                  >
                    <v-text-field
                      v-model="model.webSiteUrl"
                      type="text"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Url Sitio Web"
                    />
                  </validation-provider>
                </div>
              </div>
            </v-container>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveItem">
            Aceptar
          </v-btn>
          <v-btn color="red" text @click="close">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'

export default {
  name: 'FirstSectionModal',
  props: {
    order: {
      type: Number,
      default: null
    },
    fairId: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isActive: false,
      model: {
        id: null,
        name: null,
        webSiteUrl: null
      }
    }
  },
  methods: {
    close () {
      this.isActive = false
      this.clean()
    },
    clean () {
      this.$refs.form.reset()
      this.model = {
        name: null,
        webSiteUrl: null,
        id: null
      }
    },
    open (item) {
      if (item) {
        this.model.id = item.id
        this.model.name = item.name
        this.model.webSiteUrl = item.webSiteUrl
      }
      this.isActive = true
    },
    saveItem () {
      this.model.fairId = this.fairId
      this.$refs.form.validate().then((result) => {
        if (result) {
          if (this.model.id) {
            this.putMobile(this.url + '/' + this.model.id, this.model)
              .then((res) => {
                this.close()
                this.showSuccess(res.data.success)
                this.$emit('onClose')
              })
          } else {
            this.postMobile(this.url, this.model)
              .then((res) => {
                this.close()
                this.showSuccess(res.data.success)
                this.$emit('onClose')
              })
          }
        }
      })
    }
  },
  mixins: [
    crudServiceMobileMixin,
    notificationMixin,
    loaderMixin
  ]
}
</script>
